











































import { Component } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { ITranscripData } from '@/model/transcription/ITranscripData';
import { Icon } from '@/model/util/Icon';
import AudioDataTable from '@/components/audioTranscription/AudioDataTable.vue';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
@Component({
	name: 'MainEvaluation.vue',
	components: {
		AppContainerBase,
		AudioDataTable,
	},
})
export default class MainEvaluation extends MixinMain {
	public isLoading = false;
	public selected = '';
	public data: Array<ITranscripData> = [];
	public rows: Array<IDataTable> = [];
	public header: Array<IHeaderTable<IDataTable>> = [];
	public selectedAudio = '';
	mounted() {
		if (localStorage.getItem('filter') === null) {
			this.$router.push({
				name: 'MainTranscription',
			});
		} else {
			this.data = JSON.parse(
				localStorage.getItem('filter') as string
			) as Array<ITranscripData>;

			for (let item of this.data) {
				const row: IDataTable = {};
				row['eje'] = item.MT_Ejecutivo;
				row['date'] = item.FechaTranscripcion;
				row['audio'] = item.AudioNombre;
				row['estado'] =
					item.Estado === 'evaluado' ? 1 : 0;
				this.rows.push(row);
			}
			this.header = Object.keys(this.rows[0]).map(
				(key: string): IHeaderTable<IDataTable> => {
					let text = '';
					switch (key) {
						case 'eje':
							text = 'Agente';
							break;
						case 'date':
							text = 'Fecha';
							break;
						case 'audio':
							text = 'Audio';
							break;
						case 'estado':
							text = 'Estado';
							break;
					}
					return {
						text,
						value: key,
					};
				}
			);
		}
	}
	public get isDisabled(): boolean {
		let exit = false;
		if (this.selectedAudio === '') exit = true;
		return exit;
	}
	public listenSelectedItem(payload: IDataTable) {
		this.selectedAudio = payload.item.audio;
	}
	public sendTo() {
		if (this.selectedAudio === '') {
			Util.showMessage(
				'Debe seleccionar un audio antes de continuar',
				Icon.INFO
			);
			return;
		}
		localStorage.setItem(
			'filter',
			JSON.stringify(this.data)
		);
		localStorage.setItem(
			'audio',
			JSON.stringify(this.selectedAudio)
		);
		this.$router.push({
			name: 'Transcription',
		});
	}
	public back() {
		this.$router.push({
			name: 'MainTranscription',
		});
	}
}
