var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","single-select":true,"headers":_vm.headers,"items":_vm.data,"show-select":"","item-key":"audio","loading":_vm.isLoading,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataFound,"search":_vm.search,"custom-filter":_vm.filterByText,"footer-props":{
		'items-per-page-text': _vm.elementPerPage,
	}},on:{"item-selected":_vm.onItemSelected},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"dense":"","label":_vm.searchText},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.estado",fn:function(ref){
	var item = ref.item;
return [(item.status === 0)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Audio no cargado ")]):_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Audio cargado ")])]}},{key:"footer.page-text",fn:function(ref){
		var pageStart = ref.pageStart;
		var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }